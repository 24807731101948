/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}.__variable_d65c78 {--font-inter: '__Inter_d65c78', '__Inter_Fallback_d65c78'
}

/* latin-ext */
@font-face {
  font-family: '__DM_Serif_Display_55e350';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a9a5f1275b7cedad-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Serif_Display_55e350';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/8679c800f1e60000-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Serif_Display_Fallback_55e350';src: local("Times New Roman");ascent-override: 94.37%;descent-override: 30.51%;line-gap-override: 0.00%;size-adjust: 109.78%
}.__className_55e350 {font-family: '__DM_Serif_Display_55e350', '__DM_Serif_Display_Fallback_55e350';font-weight: 400;font-style: normal
}.__variable_55e350 {--font-serif: '__DM_Serif_Display_55e350', '__DM_Serif_Display_Fallback_55e350'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Manrope_399347';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/541f047061d17937-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Manrope_399347';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/4671b61b58d0c458-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: '__Manrope_399347';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/3b7ba2606939ec49-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Manrope_399347';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/ad7ea4a2e824917a-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Manrope_399347';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/96f64e57009dfc17-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Manrope_399347';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/788bd3eabffc4bf1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Manrope_Fallback_399347';src: local("Arial");ascent-override: 103.31%;descent-override: 29.07%;line-gap-override: 0.00%;size-adjust: 103.19%
}.__className_399347 {font-family: '__Manrope_399347', '__Manrope_Fallback_399347';font-weight: 800;font-style: normal
}.__variable_399347 {--font-manrope: '__Manrope_399347', '__Manrope_Fallback_399347'
}

@font-face {
font-family: '__gotham_58921c';
src: url(/_next/static/media/1b402ce7b0fbf8ba-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__gotham_58921c';
src: url(/_next/static/media/5306f5d5e8e4dede-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gotham_58921c';
src: url(/_next/static/media/9aa2dd90c0d39ef4-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gotham_58921c';
src: url(/_next/static/media/5a1e6f075f6c0cf5-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gotham_58921c';
src: url(/_next/static/media/9fc514cacedcaa28-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__gotham_58921c';
src: url(/_next/static/media/026843f928f424a3-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gotham_58921c';
src: url(/_next/static/media/17fdb18d579eb850-s.p.otf) format('opentype');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__gotham_58921c';
src: url(/_next/static/media/5c74e617dbb4a434-s.p.otf) format('opentype');
font-display: swap;
font-weight: 100;
font-style: italic;
}

@font-face {
font-family: '__gotham_58921c';
src: url(/_next/static/media/dfabd550981e8e61-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: '__gotham_58921c';
src: url(/_next/static/media/7066198862f4285b-s.p.otf) format('opentype');
font-display: swap;
font-weight: 200;
font-style: italic;
}@font-face {font-family: '__gotham_Fallback_58921c';src: local("Arial");ascent-override: 70.47%;descent-override: 17.62%;line-gap-override: 17.62%;size-adjust: 113.52%
}.__className_58921c {font-family: '__gotham_58921c', '__gotham_Fallback_58921c'
}.__variable_58921c {--font-gotham: '__gotham_58921c', '__gotham_Fallback_58921c'
}

